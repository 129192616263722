.data_list_container {
  background: var(--color-glass-transparency-lighter);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.data_list_container h2 {
  margin: 0.5em;
  color: var(--color-secondary-darker);
}

.list_filter_label_container {
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.list_filter_container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  position: relative;
  /* align-items: center; */
  justify-content: center;
  align-items: end;
  /* border: 1px solid green; */
}

/* .list_filter_container label {
  margin-right: 0;
} */

.filter_label {
  margin-right: 5px;
  align-self: center;
}

.filter_select {
  /* background: var(--color-glass-transparency); */
  height: 2em;
  width: fit-content;
  margin: 0.5em;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}
