$tablet: 1000px;
$mobile: 600px;

:root {
  --color-primary: rgb(125, 190, 255);
  --color-secondary: rgb(251, 194, 235);
  --color-tertiary: rgb(200, 200, 200);
  --color-accent: rgb(253, 255, 143);
  --color-primary-darker: rgb(90, 140, 190);
  // --color-secondary-darker: rgb(167, 102, 102);
  --color-secondary-darker: rgb(150, 100, 150);
  --color-primary-lighter: rgb(188, 217, 247);
  --color-secondary-lighter: rgb(253, 223, 244);
  --color-glass-transparency: rgba(255, 255, 255, 0.5);
  // --color-glass-transparency-lighter: rgba(255, 255, 255, 0.2);
  // --color-glass-transparency-lighter: rgba(180, 180, 180, 0.4);
  // --color-glass-transparency-lighter: rgba(220, 220, 220, 0.25);
  --color-glass-transparency-lighter: rgba(235, 235, 235, 0.5);

  --color-glass-transparency-less: rgba(255, 255, 255, 0.75);
  --color-glass-transparency-dark: rgba(0, 0, 0, 0.1);

  --gradient-primary: linear-gradient(
    140deg,
    var(--color-secondary-lighter) 30%,
    var(--color-primary-lighter) 100%
  );

  --gradient-body: linear-gradient(
    to top,
    var(--color-secondary) 0%,
    rgb(166, 193, 238) 100%
  );
}

html body {
  font-size: 16px;
  margin: 0px;
  width: 100vw;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
  // background: var(--gradient-body);
  background-image: url("../public/background_clouds.webp");
  background-size: cover;
  background-position-y: 0%;
  color: rgb(80, 80, 80);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // border: 1px solid black;
}

#root {
}

.App {
  // width: 90vw;
  // height: 100vh;
  // padding: 0 10px 100px 10px;
  width: 100vw;
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

#off_canvas {
  max-width: 66vw;
  background-image: url("../public/background_clouds.webp");
  background-size: cover;
}

#offCanvasHeader {
  font-family: "Shantell Sans", cursive;
  font-size: 3em;
  font-weight: 400;
  color: var(--color-secondary-darker);

  //mobile
  @media (max-width: calc($tablet - 1px)) {
    font-size: 2.5em;
    margin-bottom: 0;
  }
}

#signedInAs {
  font-size: 1.2em;
  color: rgb(85, 85, 85);
}

#welcomeUser {
  font-size: 1em;
  color: rgb(85, 85, 85);
}

.offCanvasBody {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;

  .offCanvasChild {
    width: 100%;
    padding-top: 1em;
    border-top: 1px solid var(--color-primary-darker);
  }
}

.csv_btn_container {
  width: 100%;
  min-height: 50%;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-around;
  // border: 1px solid black;
}

.csv_btn_wrapper {
  width: 40%;
  height: 100%;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 10%;
  // border: 1px solid red;

  //mobile
  @media (max-width: calc($tablet - 1px)) {
    width: 66%;
    margin-right: 0%;
    justify-content: space-evenly;
  }
}

.csvIcon {
  height: 50%;
  width: auto;
  position: absolute;
  top: 25%;
  left: 5%;
  opacity: 0.5;
  z-index: -1;

  //mobile
  @media (max-width: calc($tablet - 1px)) {
    width: 50%;
    left: 0%;
  }
}

.deleteDataBtn {
  margin-bottom: 2em;
}

#backgroundCitation {
  position: absolute;
  bottom: 0px;
  left: 5px;
  width: 100%;
  text-align: left;
}

input,
#myFile {
  margin: 1em 0 0 3%;
}

.warningModal {
  width: 60vw;
  height: 60vh;
  background: white;
  color: darkred;
  border: none;
  border-radius: 8px;
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(30vw, 30vh);
  //mobile
  @media (max-width: calc($tablet - 1px)) {
    width: 90vw;
    height: 50vh;
    text-align: center;
  }
}

.confirmDeleteModal {
  background: rgb(135, 30, 30);
  width: 50vw;
  height: 80vh;
}

#header {
  font-family: "Shantell Sans", cursive;
  font-size: 3.5em;
  position: absolute;
  top: 10px;
  right: 5vw;
  color: var(--color-secondary-darker);
  // color: rgb(50, 50, 50);
  font-weight: 400;

  //mobile
  @media (max-width: calc($tablet - 1px)) {
    width: 50vw;
    position: absolute;
    right: 0px;
    transform: translateX(-25vw);
    text-align: center;
  }
}

#user_icon_btn {
  height: 2.5em;
  width: 2.5em;
  cursor: pointer;
  color: var(--color-secondary-darker);

  //large display
  @media (min-width: $tablet) {
    color: var(--color-secondary-darker);
  }
}

.react-icons-user {
  height: 200px;
  width: 200px;
  font-size: 3em;
}

.dashboard {
  width: 92vw;
  height: auto;
  min-height: 100vh;
  padding-top: 65px;
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;

  display: grid;

  grid-template-columns: repeat(4, 1fr);
  // grid-template-rows: repeat(5, auto);
  grid-gap: 2em;

  grid-template-areas:
    "list chart chart chart"
    "list chart chart chart"
    "list input input input";
  .grid-list_container {
    grid-area: list;
    justify-self: center;
    align-self: top;
    width: 100%;
  }
  .grid-chart_container {
    grid-area: chart;
    justify-self: center;
    align-self: top;
    width: 90%;
  }
  .grid-input_container {
    // grid-area: input;
    // justify-self: end;
    // align-self: top;
    display: flex;
    justify-content: center;
    // transform: translateY(-50%);
    position: fixed;
    bottom: 0.5em;
    right: 0.5em;

    //mobile
    @media (max-width: calc($tablet - 1px)) {
      transform: none;
    }
  }

  /* mobile */
  @media (max-width: calc($tablet - 1px)) {
    width: 100vw;
    height: auto;
    grid-template-columns: 1fr;
    grid-column-gap: 10;

    grid-template-areas:
      "input"
      "chart"
      "chart"
      "list";
  }
  .grid-input_container {
    // width: 90%;
  }
  .grid-chart_container {
    width: 100%;
  }
}

.graph_input_container {
  // display: flex;
  // flex-direction: column;
  // width: 60vw;
  // margin: 2em 1em;
  // border-radius: 12px;
  // align-items: center;
  // justify-content: center;
}

.dashboard__container {
  display: absolute;
  position: fixed;
  left: 0;
  top: 0;
}

.dashboard__login_container {
  position: fixed;
  top: 0.5em;
  left: 0.5em;
  z-index: 2;

  @media (max-width: calc($tablet - 1px)) {
    label {
      display: none;
    }
  }
}

.logout_btn {
  border: none;
  color: black;
  padding: 0.4em 0.5em;
  border-radius: 5px;
}
