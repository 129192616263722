$tablet: 1000px;
$mobile: 600px;

// .input_form_container {
//   /* if on mobile, disable desktop menu */
//   @media (max-width: calc($tablet - 1px)) {
//     display: none;
//   }
// }

.input_form_container {
  z-index: 200;
  #input_add_btn {
    height: 2.5em;
    width: 2.5em;
    // position: fixed;
    // top: 0.5em;
    // right: 0.5em;
  }

  /* if large screen */
  @media (min-width: $tablet) {
    width: 300px;
    height: 50px;
    background: var(--color-glass-transparency-lighter);
    border-radius: 12px;
    padding: 1em;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    display: flex;
    align-items: center;
    justify-content: space-around;

    #input_add_btn {
      position: initial;
      display: block;
      cursor: pointer;
      // color: var(--color-primary-darker);
      color: var(--color-secondary-darker);

      // border: 1px solid white;
      // background: white;
      border-radius: 50%;
    }
  }

  .add-data_and_header_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    .add-data_header {
      text-align: center;
      margin: 0;
    }
    p {
      font-size: 1.5em;
    }

    //mobile
    @media (max-width: calc($tablet - 1px)) {
      height: 2.5em;
      width: 2.5em;
      position: fixed;
      top: 0.5em;
      right: 0.5em;
      color: var(--color-secondary-darker);

      .add-data_header {
        display: none;
      }
    }
  }
}

/* if no pets, put + button front and center */
.no_pets-btn {
  @media (min-width: $tablet) {
    position: fixed;
    left: calc(50vw - 150px);
    top: 50%;
  }
}

/* if no pets, show paragraph in the middle */
.no_pets-para {
  width: 100vw;
  position: fixed;
  top: 30vh;
  left: 0px;
  text-align: center;
  padding: 0 10% 0 10%;
}

.input_modal .modal-content {
  background-color: white;
}

.modal_form_select_container {
  width: 100%;
  min-height: 20vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  .modal_form_select_button {
    height: 100px;
    width: 100px;
    border: none;
    border-radius: 20px;
    padding: 5px;
    font-size: 3em;
    color: var(--color-primary-darker);
    // background: var(--color-glass-transparency-dark);
    background: white;
    border: 2px solid var(--color-primary-darker);
  }
  .modal_form_btn_label_wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: cetner;
    align-items: center;
    label {
      font-size: 1.4em;
      margin-bottom: 0.5rem;
    }
  }
}

#weight_submit_form {
  // height: 100%;
  // width: 70%;
  // display: flex;
  /* flex-direction: row; */
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr 0.2fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 0px;
  grid-template-areas:
    "pet pet x"
    "date date ."
    "weight unit add";
}

.formtext {
  background: var(--color-glass-transparency-dark);
  border: none;
  height: 2em;
  min-width: 15vw;
  width: 60%;
  padding: 5px;
  margin: 0.5em;
  border-radius: 8px;
  text-align: center;

  #name {
    grid-area: pet;
  }
}

#weigh_date {
  height: 2em;
  min-width: 2em;
  max-width: 20%;
  border: none;
  padding: 5px;
  background: var(--color-glass-transparency-dark);
  border-radius: 8px;
  cursor: pointer;
}

.form_select {
  background: var(--color-glass-transparency-dark);
  color: black;
  height: 2em;
  width: fit-content;
  margin: 0.5em;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.main_btns_container {
  width: 60%;
  display: flex;
  justify-content: space-around;
}

.form_btn {
  border: none;
  color: black;
  // margin: 1em;
  // padding: 5px;
  border-radius: 8px;
  cursor: pointer;
  transition: 200ms;
  background: var(--color-glass-transparency);
}

/* for primary two buttons which toggle forms */
.form_btn_main {
  height: 3em;
  width: 8vw;
}

.form_btn_add {
  width: 2.5rem;
  height: 2.5rem;
  background: rgba(20, 200, 20, 0.4);

  grid-area: add;
  align-self: end;
}

.form_btn:hover {
  background-color: var(--color-glass-transparency-dark);
}

.form_return {
  background: rgba(255, 20, 20, 0.4);
  height: 2.5rem;
  width: 2.5rem;

  grid-area: x;
}

#weigh_date {
  padding: 5px;
  margin: 0.5em;
  /* width: 150px; */
  min-width: 120px;

  // grid-area: date;
}

.form_sub_container {
  // width: 100%;
  // display: flex;
  // flex-direction: row;
  /* justify-content: center; */
  // align-items: center;
  label {
    opacity: 0.7;
  }
}
.form_sub_container-pet {
  grid-area: pet;
  display: flex;
  flex-direction: column;
}
.form_sub_container-date {
  grid-area: date;
  display: flex;
  flex-direction: column;
}
.form_sub_container-weight {
  grid-area: weight;
}
.form_sub_container-unit {
  grid-area: unit;
  display: flex;
  flex-direction: column;
}

label {
  margin-right: 1em;
  margin-left: 1em;
}

.hide {
  display: none;
}
