$tablet: 1000px;
$mobile: 600px;
.chart_container {
  /* width: 100%;
  height: 80%; */
  background: var(--color-glass-transparency-lighter);
  padding: 1em;
  border-radius: 12px;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: relative;
  max-width: 100vw;

  @media (max-width: calc($tablet - 1px)) {
    padding: 0;
    margin: 0;
  }
}

.chart_container:before {
  content: "";
  background-image: url("../../public/backgroundpig4.png");
  position: absolute;
  top: 20%;
  right: 0px;
  bottom: 0px;
  left: 0%;
  background-size: stretch;
  background-repeat: no-repeat;
  background-position: top;
  transform: scale(1) rotate(0deg);
  opacity: 0.15;
  z-index: -1;
  @media (max-width: calc($tablet - 1px)) {
    top: 0px;
    scale: 1;
  }
}

.list_trim_label_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.filter_select {
  // background: var(--color-glass-transparency);
  background: rgba(50, 50, 50, 0.15);
  height: 1.5em;
  width: fit-content;
  // border: 1px solid var(--color-primary-darker);
  border-radius: 8px;
  margin-right: 5px;
  cursor: pointer;
  padding: 1px;
}
