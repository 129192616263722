.list_card {
  min-height: 6em;
  width: 90%;
  max-width: 500px;
  background: var(--color-glass-transparency-lighter);
  margin: 0.5em;
  padding: 20px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  position: relative;
  transition: 200ms ease-in-out;
  overflow: hidden;
}

.card_color_tag {
  position: absolute;
  top: 0;
  left: 0;

  width: 100px;
  height: 100px;
  border-top-left-radius: 5px;
  transform: rotate(45deg) translate(-100px, 0px);
}

/* .card_color_tag::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;

  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;

  border-right: 15px solid red;

  transform: rotate(0deg) translate(0px, 0px);
} */

.list_card:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

#card_name {
  font-size: 1em;
  position: absolute;
  top: 10%;
  left: 5%;
}

#card_weight {
  display: flex;
  /* position: absolute;
  left: 40%;
  top: 50%; */
  justify-content: center;
  align-items: center;
  transform: translateY(1em);
  width: 100%;
}

#weight_val {
  font-size: 2em;
  margin-right: 5px;
  font-weight: 900;
  color: var(--color-primary-darker);
}

#card_unit {
  font-size: 0.6em;
  transform: translateY(-1em);
}

#card_date {
  font-size: 0.7em;
  color: gray;
  position: absolute;
  right: 10px;
  top: 10px;
}

.trash {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: rgba(200, 10, 10, 0.7);
  cursor: pointer;
  transition: 200ms;
}

.trash:hover {
  color: red;
}
